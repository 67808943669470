
@media screen and (min-width: 320px) and (max-width: 575px) {
    .welcome-message {
        padding-left: 0;
        margin-bottom: 1rem;
        }
}

@media screen and (min-width: 576px) {
    .welcome-message {
        padding-left: 0;
    }
}


@media screen and (min-width: 320px) and (max-width: 575px) {
    .strong-welcome-message {
        font-size: 14px;
    }
}

@media screen and (min-width: 320px) and (max-width: 768px) {
    .legend-icons-targets {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    .margin-legend-icons {
        margin: 0 0.5rem 0.5rem 0;
    }

    .small-legend-icons {
        width: 28px;
        height: 28px;
        margin-right: 2px;
    }

    .small-legend-icons-translate-icons {
        transform: translate(-7px, -4px);
    }

    .legend-text {
        font-style: italic;
        font-size: 12px;
        position: relative;
        top: -3px;
    }
}

@media screen and (min-width: 769px) {
    .legend-icons-targets {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    .legend-text {
        font-style: italic;
        font-size: 14px;
    }
}