@media screen and (min-width: 320px) and (max-width: 720px) {
    .dateTime-picker > .rdtPicker {
        position: absolute;
        right: 0;
    }
}

@media screen and (min-width: 320px) and (max-width: 720px) {
    .dateTime-picker-middle > .rdtPicker {
        position: absolute;
        right: -100%;
    }
}