.flag-select__option__icon {
    top: -0.05em !important;
}

.translations {
    margin-bottom: 0.5rem;
}

.label-file {
    cursor: pointer;
    color: #B59A4F;
    font-weight: bold;
    padding: 0.5rem;
    border: 1px solid #B59A4F;
    border-radius: 1rem;
}

.label-file:hover {
    background-color: #B59A4F1c;
}

.input-file {
    display: none;
}

.form-group a {
    color: #B59A4F;
}
