.spinner-border-custom {
    margin-left: 50%;
    color: #B59A4F ;
    width: 6rem ;
    height: 6rem ;
    border: .5rem solid currentColor;
    border-radius: 30% ;
}

.frame-container {
    min-height: 100vh;
}

.frame-parent-row > .tab-content {
    width: 100%;
}

@media screen and (min-width: 768px) {
    .informations-container {
        display: flex;
        flex-direction: row;
        width: 100%;
    }    
}

@media screen and (min-width: 768px) {
    .information-left-container {
        max-width: 50%;
        margin-right: 2rem;
    }
}

@media screen and (min-width: 320px) and (max-width: 767px){
    .information-left-container {
        margin-right: 2rem;
    }
}

.settings-dropdown-content-container {
    background-color: transparent;
    top: 40px !important;
    transform: none !important;
    left: -108px !important;
    z-index: 9999 !important;
    border: none !important;
    /* padding: 0; */
    /* height: 100%; */
}

.settings-dropdown-item {
    padding-right: 0;
    padding-left: 0;
    width: 100%;
}

.settings-dropdown-item > button {
    position: relative;
    float: right;
    font-weight: bold;
    color: white;
    width: 100%;
}

.settings-dropdown-item > button:hover {
    color: black;
}

.settings-dropdown-item:hover {
    background-color: transparent;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
    .text-right-nav {
        text-align: left !important;
    }
}

@media screen and (min-width: 768px) {
    .text-right-nav {
        text-align: right !important;
    }
}

.autocomplete-user-input {
    width: 100% !important;
    background-color: white;
    height: calc(1.5em + .75rem + 2px);
}

@media screen and (min-width: 320px) and (max-width: 767px) {
    .margin-spacing-top {
        margin-top: 1rem;
    }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
    .margin-spacing-pen-horizontal {
        margin: 0 10px;
    }
}

#upload-photo {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

.upload-photo-informations {
    cursor: pointer;
    font-family: 'Helvetica Neue', 'Roboto', sans-serif;
    display: inline-block;
    font-weight: 400;
    color: #fff;
    background-color: #B59A4F;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.upload-photo-informations:hover {
    color: #000;
}