@media (min-width: 1200px) {
    .campaign-general-menu {
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
        max-width: 1140px;
        margin-top: 2rem;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .campaign-general-menu {
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
        max-width: 960px;
        margin-top: 2rem;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .campaign-general-menu {
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
        max-width: 720px;
        margin-top: 2rem;
    }
}

@media (min-width: 576px) and (max-width: 767px) {
    .campaign-general-menu {
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
        max-width: 540px;
        margin-top: 2rem;
    }
}

@media (min-width: 375px) and (max-width: 575px) {
    .campaign-general-menu {
        width: 100%;
        padding-right: 15px;
        /* padding-left: 15px; */
        margin-right: auto;
        margin-left: auto;
        margin-top: 2rem;
    }
}

@media (min-width: 320px) and (max-width: 374px) {
    .campaign-general-menu {
        width: 100%;
        padding-right: 15px;
        /* padding-left: 15px; */
        margin-right: auto;
        margin-left: auto;
        margin-top: 2.5rem;
    }
}

@media (min-width: 1200px) {
    .campaign-manager-tabs {
        width: 100%;
        padding-right: 30px;
        padding-left: 30px;
        margin-right: auto;
        margin-left: auto;
        max-width: 1140px;
        margin-top: 2rem;
        font-family: 'Helvetica Neue', 'Roboto', sans-serif;
    }
    .campaign-manager-tabs > .nav-item.active {
        /* background-color: #B59A4F; */
        background-color: rgb(175, 39, 25, 1);
        color: #fff;
        font-family: 'Helvetica Neue', 'Roboto', sans-serif;
        font-weight: bold;
        opacity: 1;
        text-decoration: none;
    }
    .campaign-manager-tabs > .nav-item {
        /* background-color: #B59A4F; */
        background-color: rgb(203, 114, 105);
        color: #000;
        font-family: 'Helvetica Neue', 'Roboto', sans-serif;
        font-weight: bold;
        opacity: 0.5;
        text-decoration: none;
        margin-right: 0.5rem;
    }
    .campaign-manager-tabs > .nav-item.active:hover {
        /* background-color: #B59A4F; */
        background-color: rgb(175, 39, 25, 1);
        color: #000;
        font-family: 'Helvetica Neue', 'Roboto', sans-serif;
        font-weight: bold;
        opacity: 1;
        text-decoration: none;
    }

    .campaign-manager-tabs > .nav-item:hover {
        /* background-color: #B59A4F; */
        background-color: rgb(175, 39, 25, 1);
        /* -webkit-transition: background-color 250ms linear;
        -ms-transition: background-color 250ms linear;
        transition: background-color 250ms linear; */
        color: #fff;
        font-family: 'Helvetica Neue', 'Roboto', sans-serif;
        font-weight: bold;
        opacity: 1;
        text-decoration: none;
        margin-right: 0.5rem;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .campaign-manager-tabs {
        width: 100%;
        padding-right: 30px;
        padding-left: 30px;
        margin-right: auto;
        margin-left: auto;
        max-width: 960px;
        margin-top: 2rem;
        font-family: 'Helvetica Neue', 'Roboto', sans-serif;

    }
    .campaign-manager-tabs > .nav-item.active {
        background-color: rgb(175, 39, 25, 1);
        color: #fff;
        font-family: 'Helvetica Neue', 'Roboto', sans-serif;
        font-weight: bold;
        opacity: 1;
        text-decoration: none;
    }
    .campaign-manager-tabs > .nav-item {
        background-color: rgb(203, 114, 105);
        color: #000;
        font-family: 'Helvetica Neue', 'Roboto', sans-serif;
        font-weight: bold;
        opacity: 0.5;
        text-decoration: none;
        margin-right: 0.5rem;
    }

    .campaign-manager-tabs > .nav-item:hover {
        /* background-color: #B59A4F; */
        background-color: rgb(175, 39, 25, 1);
        /* -webkit-transition: background-color 250ms linear;
        -ms-transition: background-color 250ms linear;
        transition: background-color 250ms linear; */
        color: #fff;
        font-family: 'Helvetica Neue', 'Roboto', sans-serif;
        font-weight: bold;
        opacity: 1;
        text-decoration: none;
        margin-right: 0.5rem;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .campaign-manager-tabs {
        width: 100%;
        padding-right: 30px;
        padding-left: 30px;
        margin-right: auto;
        margin-left: auto;
        max-width: 720px;
        margin-top: 2rem;
        font-family: 'Helvetica Neue', 'Roboto', sans-serif;

    }
    .campaign-manager-tabs > .nav-item.active {
        background-color: rgb(175, 39, 25, 1);
        color: #fff;
        font-family: 'Helvetica Neue', 'Roboto', sans-serif;
        font-weight: bold;
        opacity: 1;
        text-decoration: none;
    }
    .campaign-manager-tabs > .nav-item {
        background-color: rgb(203, 114, 105);
        color: #000;
        font-family: 'Helvetica Neue', 'Roboto', sans-serif;
        font-weight: bold;
        opacity: 0.5;
        text-decoration: none;
        margin-right: 0.5rem;
    }

    .campaign-manager-tabs > .nav-item:hover {
        /* background-color: #B59A4F; */
        background-color: rgb(175, 39, 25, 1);
        /* -webkit-transition: background-color 250ms linear;
        -ms-transition: background-color 250ms linear;
        transition: background-color 250ms linear; */
        color: #fff;
        font-family: 'Helvetica Neue', 'Roboto', sans-serif;
        font-weight: bold;
        opacity: 1;
        text-decoration: none;
        margin-right: 0.5rem;
    }
}

@media (min-width: 320px) and (max-width: 767px) {
    .campaign-manager-tabs {
        width: 100%;
        padding-right: 30px;
        padding-left: 30px;
        margin-right: auto;
        margin-left: auto;
        max-width: 540px;
        margin-top: 2rem;
        font-family: 'Helvetica Neue', 'Roboto', sans-serif;

    }
    .campaign-manager-tabs > .nav-item.active {
        background-color: rgb(175, 39, 25, 1);
        color: #fff;
        font-family: 'Helvetica Neue', 'Roboto', sans-serif;
        font-weight: bold;
        opacity: 1;
        text-decoration: none;
    }
    .campaign-manager-tabs > .nav-item {
        background-color: rgb(203, 114, 105);
        color: #000;
        font-family: 'Helvetica Neue', 'Roboto', sans-serif;
        font-weight: bold;
        opacity: 0.5;
        text-decoration: none;
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
    }

    .campaign-manager-tabs > .nav-item:hover {
        /* background-color: #B59A4F; */
        background-color: rgb(175, 39, 25, 1);
        /* -webkit-transition: background-color 250ms linear;
        -ms-transition: background-color 250ms linear;
        transition: background-color 250ms linear; */
        color: #fff;
        font-family: 'Helvetica Neue', 'Roboto', sans-serif;
        font-weight: bold;
        opacity: 1;
        text-decoration: none;
        margin-right: 0.5rem;
    }
}


@media screen and (min-width: 992px) {
    .campaign-video-manager-main-container {
        display: flex;
        flex-direction: row;
    }
}

@media screen and (min-width: 320px) and (max-width: 991px) {
    .campaign-video-manager-main-container {
        display: flex;
        flex-direction: column;
    }
}

.campaign-video-manager-separator {
    height: 1px;
    border: 1px solid #B59A4F;
}

@media screen and (min-width: 992px) {
    .campaign-video-manager-tree-container {
        width: 30%;
        overflow: auto;
        border-right: 1px solid #B59A4F;
        margin-right: 1rem;
        padding-right: 1rem;
        padding: 0 1rem 1rem 0;
    }
}

@media screen and (min-width: 320px) and (max-width: 991px) {
    .campaign-video-manager-tree-container {
        width: 100%;
        border-bottom: 1px solid #B59A4F;
        margin-right: 1rem;
        height: 250px;
    }
}

@media screen and (min-width: 992px) {
    .campaign-video-manager-tree-subcontainer {
        overflow: auto;
    }
}

@media screen and (min-width: 320px) and (max-width: 991px) {
    .campaign-video-manager-tree-subcontainer {
        height: 200px;
        overflow-y: scroll;
        width: 100%;
        padding: 0 1rem 1rem 0;
    }
}

@media screen and (min-width: 992px) {
    .campaign-video-manager-video-container {
        width: 70%;
        padding: 0 1rem 0 1rem;
        min-height: 350px;
    }
}

@media screen and (min-width: 320px) and (max-width: 991px) {
    .campaign-video-manager-video-container {
        width: 100%;
        padding: 1rem 1rem 0 1rem;
        height: 350px;
    }
}

@media screen and (min-width: 320px) and (max-width: 991px) {
    .campaign-video-manager-video-subcontainer {
        display: flex;
        flex-direction: column;
        height: 300px;
        overflow-y: scroll;
    }
}

@media screen and (min-width: 992px) {
    .campaign-video-manager-video-subcontainer {
        display: flex;
        flex-direction: column;
    }
    
}

.campaign-video-manager-video-thumbnail-container {
    width: 100%;
    /* max-height: 300px; */
    overflow: auto;
    padding: 1rem 1rem 1rem 0;
    display: flex;
    flex-direction: column;
}

.campaign-video-media-list-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 0.5rem;
}

.campaign-video-media-list-item {
    height: 60px;
    width: 60px;
    cursor: pointer;
    overflow: hidden;
    margin-right: 1rem;
}

.campaign-video-media-list-item-title {
    transform: translateY(15px);
    /* width: 100px;
    text-overflow: ellipsis; */
    white-space: nowrap; 
    width: 70%; 
    overflow: hidden;
    text-overflow: ellipsis; 
    border: 1px solid #000000;
}

@media screen and (min-width: 320px) and (max-width: 575px) {
    .campaign-title-padding {
        padding-left: 15px;
    }
}
