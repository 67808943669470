.Signin {
    position: relative;
    width: 100%;
    height: 100%;
}

.Signin-Form-Container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    border: 2px solid #ffffff;
    width: 300px;
}

.Signin-Form-Logo {
    margin: 20px auto 10px auto;
    width: 250px;
    display: block;
}

.Signin-Form-Title {
    text-align: center;
    margin-bottom: 10px;
}

.Signin-Form {
    margin-bottom: 10px;
}

.Signin-Text {
    font-size: 1.2em;
    text-align: center;
    font-weight: bold;
    margin: 10px auto 20px auto;
    color: #49992B;
}

.Signin-Copyright {
    font-size: 0.9em;
    margin-bottom: 10px;
    margin-top: 10px;
}

.signin-forgotten-password {
    text-decoration: none;
}

.signin-forgotten-password:hover {
    color: #B59A4F;
    text-decoration: none;
}