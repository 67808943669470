.rdtPicker {
    color: black !important;
}

.justify-content-md-center > .col-md-12 > .table-responsive-md {
    overflow: auto;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
    .common-container-padding {
        padding-top: 0;
    }
}

@media screen and (min-width: 768px) {
    .common-container-padding {
        padding-top: 30px;
    }
}