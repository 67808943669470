/* @media screen and (max-width: 768px) {
    .stat-chart-container {
        overflow-x: scroll;
    }
} */

.stat-chart-container {
    /* max-width: 300px; */
}

@media screen and (min-width: 320px) and (max-width: 768px) {
    .reset-button-margin-top {
        margin-top: 0.5rem;
    }
}
