/** NAVBAR OVERRIDE */

.navbar-custom {
    background-color: #000000;
  }
  .navbar-custom .navbar-brand {
    color:#B59A4F;
  }
  .navbar-custom .navbar-brand:hover,
  .navbar-custom .navbar-brand:focus {
    color:#B59A4F;
  }
  .navbar-custom .navbar-text {
    color: #ffffff;
  }
  .navbar-custom .navbar-text a {
    color: #000000;
  }
  .navbar-custom .navbar-text a:hover,
  .navbar-custom .navbar-text a:focus {
    color: #000000; 
  }
  .navbar-custom .navbar-nav .nav-link {
    color: #ffffff;
    border-radius: .25rem;
    margin: 0 0.25em;
  }
  .navbar-custom .navbar-nav .nav-link:not(.disabled):hover,
  .navbar-custom .navbar-nav .nav-link:not(.disabled):focus {
    color: #000000;
    background-color:#B59A4F;
  }
  .navbar-custom .navbar-nav .nav-link.active,
  .navbar-custom .navbar-nav .nav-link.active:hover,
  .navbar-custom .navbar-nav .nav-link.active:focus {
    color: #000000;
    background-color:#B59A4F;
  }
  .navbar-custom .navbar-nav .nav-item.active .nav-link,
  .navbar-custom .navbar-nav .nav-item.active .nav-link:hover,
  .navbar-custom .navbar-nav .nav-item.active .nav-link:focus,
  .navbar-custom .navbar-nav .nav-item.show .nav-link,
  .navbar-custom .navbar-nav .nav-item.show .nav-link:hover,
  .navbar-custom .navbar-nav .nav-item.show .nav-link:focus {
    color: #000000;
    background-color:#B59A4F;
  }
  .navbar-custom .navbar-toggle {
    border-color:#B59A4F;
  }
  .navbar-custom .navbar-toggle:hover,
  .navbar-custom .navbar-toggle:focus {
    background-color:#B59A4F;
  }
  .navbar-custom .navbar-toggler .navbar-toggler-icon {
    color: #ffffff;
  }
  .navbar-custom .navbar-collapse,
  .navbar-custom .navbar-form {
    border-color: #ffffff;
  }
  .navbar-custom .navbar-link {
    color: #ffffff;
  }
  .navbar-custom .navbar-link:hover {
    color: #000000;
  }
  
  @media (max-width: 575px) {
    .navbar-expand-sm .navbar-nav .show .dropdown-menu .dropdown-item {
      color: #ffffff;
    }
    .navbar-expand-sm .navbar-nav .show .dropdown-menu .dropdown-item:hover,
    .navbar-expand-sm .navbar-nav .show .dropdown-menu .dropdown-item:focus {
      color: #000000;
    }
    .navbar-expand-sm .navbar-nav .show .dropdown-menu .dropdown-item.active {
      color: #000000;
      background-color:#B59A4F;
    }
  }
  
  @media (max-width: 767px) {
    .navbar-expand-md .navbar-nav .show .dropdown-menu .dropdown-item {
      color: #ffffff;
    }
    .navbar-expand-md .navbar-nav .show .dropdown-menu .dropdown-item:hover,
    .navbar-expand-md .navbar-nav .show .dropdown-menu .dropdown-item:focus {
      color: #000000;
    }
    .navbar-expand-md .navbar-nav .show .dropdown-menu .dropdown-item.active {
      color: #000000;
      background-color:#B59A4F;
    }
  }
  
  @media (max-width: 991px) {
    .navbar-expand-lg .navbar-nav .show .dropdown-menu .dropdown-item {
      color: #ffffff;
    }
    .navbar-expand-lg .navbar-nav .show .dropdown-menu .dropdown-item:hover,
    .navbar-expand-lg .navbar-nav .show .dropdown-menu .dropdown-item:focus {
      color: #000000;
    }
    .navbar-expand-lg .navbar-nav .show .dropdown-menu .dropdown-item.active {
      color: #000000;
      background-color:#B59A4F;
    }
  }
  
  @media (max-width: 1199px) {
    .navbar-expand-xl .navbar-nav .show .dropdown-menu .dropdown-item {
      color: #ffffff;
    }
    .navbar-expand-xl .navbar-nav .show .dropdown-menu .dropdown-item:hover,
    .navbar-expand-xl .navbar-nav .show .dropdown-menu .dropdown-item:focus {
      color: #000000;
    }
    .navbar-expand-xl .navbar-nav .show .dropdown-menu .dropdown-item.active {
      color: #000000;
      background-color:#B59A4F;
    }
  }
  
  .navbar-expand .navbar-nav .show .dropdown-menu .dropdown-item {
    color: #ffffff;
  }
  .navbar-expand .navbar-nav .show .dropdown-menu .dropdown-item:hover,
  .navbar-expand .navbar-nav .show .dropdown-menu .dropdown-item:focus {
    color: #000000;
  }
  .navbar-expand .navbar-nav .show .dropdown-menu .dropdown-item.active {
    color: #000000;
    background-color:#B59A4F;
  }
  .flag{
	width:20px;
	margin-right:3px;
	cursor:pointer;
}

/* @media screen and (min-width: 320px) and (max-width: 360px) {
  .image-size-navigation-bar {
    height: 25px;
  }
}

@media screen and (min-width: 361px) and (max-width: 379px) {
  .image-size-navigation-bar {
    height: 30px;
  }
}

@media screen and (min-width: 380px) and (max-width: 399px) {
  .image-size-navigation-bar {
    height: 35px;
  }
} */

  .image-size-navigation-bar {
    height: 50px;
  }

.settings-dropdown-content-container {
  background-color: transparent;
  top: 40px !important;
  transform: none !important;
  left: -108px !important;
  border: none !important;
  z-index: 999;
  /* padding: 0; */
  /* height: 100%; */
}

.settings-dropdown-item {
  padding-right: 0;
  padding-left: 0;
  width: 100%;
}

.settings-dropdown-item > button {
  position: relative;
  float: right;
  font-weight: bold;
  color: white;
  width: 100%;
}

.settings-dropdown-item > button:hover {
  color: black;
}

.settings-dropdown-item:hover {
  background-color: transparent;
}

@media screen and (min-width: 1024px) {
  .language-selector {
    top: 66px;
    height: 40px;
    z-index: 1040;
    background-color: transparent;
    margin-top: 0.5rem;
  }
}

@media screen and (min-width: 320px) and (max-width: 1023px){
  .language-selector {
    top: 66px;
    height: 40px;
    z-index: 1040;
    background-color: transparent;
  }
}

@media screen and (min-width: 1024px) {
  .padding-language-content {
    padding-right:30px;
  }
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .padding-language-content {
    padding-right:15px;
  }
}
