html,
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
}

#root,
.MyArtAdmin {
  height: 100%;
  width: 100%;
}

.trash-button i {
  cursor: pointer;
}

.trash-button i:hover {
  color:#B59A4F;
}

.clickable {
  cursor: pointer;
}

.sorted-DESC {
  background-image: url('./common/caret-up.png');
  background-size: 15px auto;
  background-repeat: no-repeat;
  background-position: right;
}

.sorted-ASC {
  background-image: url('./common/caret-down.png');
  background-size: 15px auto;
  background-repeat: no-repeat;
  background-position: right;
}

/** LAYOUT */
@media screen and (min-width: 361px) {
  .Common-Container {
    padding-top: 30px;
  }
}

@media screen and (min-width: 320px) and (max-width: 360px) {
  .Common-Container {
    padding-top: 80px;
  }
}


/** THEMES */

.modal-content {
  color: #000000;
}

body {
  background-color: #000000;
  color: #ffffff;
}


/* buttons color */

.btn-red {
  background-color:  rgb(175, 39, 25, 1);
  color: #000000;
}

.btn-red:disabled {
  cursor: not-allowed;
}

.btn-red-link {
  background-color: #000000;
  color: #ffffff;
}

.btn-red-link:hover {
  background-color:   #B59A4F;
  color: #000000;
}

.btn-red-link-dark {
  background-color: #573F00;
  color: #ffffff;
}

.btn-red-link-dark:hover {
  background-color:   #B59A4F;
  color: #000000;
}

.small-button {
  width: 40px;
  height: 38px;
}

.btn-gold {
  background-color: #B59A4F;
  color: #000000;
}

.btn-gold:disabled {
  cursor: not-allowed;
}

.btn-gold-link {
  background-color: #000000;
  color: #ffffff;
}

.btn-gold-link:hover {
  background-color: #B59A4F;
  color: #000000;
}

.btn-gold-link-dark {
  background-color: #573F00;
  color: #ffffff;
}

.btn-gold-link-dark:hover {
  background-color: #B59A4F;
  color: #000000;
}

.table-custom {
  margin: 20px 0px 20px 0px;
  background-color: #000000;
  color: #ffffff;
}

.table-custom td {
  text-align: center;
}

.table-custom th {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #000000;
  font-weight: bold;
  text-align: center;
}

.table-custom tbody td {
  border-right: 1px solid #ffffff;
  border-bottom: 1px solid #ffffff;
}

.table-custom tbody td:last-child {
  border-right: 0px;
}

.table-custom tbody tr:last-child td {
  border-bottom: 0px solid #ffffff;
}

.table tr.odd {
  background: #3d2d00;
}

.table tr.even {
  background: black;
}

@media (max-width: 640px) {

	.frames_th_checkbox{
		max-width: 20px;
	}

	.frames_th_index{
		max-width: 20px;
	}

	.frames_th_target{
		max-width: 20px;
	}

	.frames_th_name{
		min-width: 150px;
	}
}

.Admin {
  padding-top: 104px;
}

.Home {
  padding-top: 66px;
}

.react-datetime-picker__wrapper {
  border: 0 !important;
}

.ColorField {
  padding: 0;
  width: 30px;
}

a,
a:visited,
a:active,
a:hover {
  color: #ffffff;
  text-decoration: underline;
}

.Button-With-Badge {
  position: relative;
  z-index: 10;
}

.Button-Badge {
  top: -5px;
  right: -5px;
  position: absolute;
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #af2719;
  color: #ffffff;
  text-align: center;
  font-size: 0.7em;
  padding: 1px 0px 0px 2px;
  border: 1px solid #af2719;
  z-index: 110;
}

.Uppercase-Title {
  font-family: 'Helvetica Neue', 'Roboto', sans-serif;
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;
  color: #B59A4F;
}

@media screen and (min-width: 768px) {
  .margin-icons-account {
    margin: 0 5px 0 10px;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .margin-icons-account {
    margin: 0 10px 0 10px;
  }
}
