.main-container-user-rights {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.main-container-user-rights-responsive {
    display: flex;
    flex-direction: column;
}

.user-rights-accordion-item-container {
    width: 100%;
}

.user-rights-accordion-item {
    border-bottom: 1px solid white;
    width: 100%;
    min-height: 50px;
    padding: 5px 10px 0 10px;
    display: flex;
    flex-direction: row;
}

.user-rights-accordion-item-text {
    transform: translateY(7px);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
