.Footer {
    padding-top:30px;
    width:300px;
    color: white;
    margin:auto;
    font-size:0.8em;
    text-align: center;
  }

  .Footer ul  {
      margin-bottom:5px;
  }

  .Signin .Footer {
      position:absolute;
      bottom:0;
      width:100%;
  }