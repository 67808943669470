.PageToggle {
    position: fixed;
	top: 80px;
    z-index: 50;
    background-color:black;
    width: 100%;
    padding-bottom:5px;
}

.PageToggle .btn-gold {
    color:white;
}

.PageToggle .btn-gold-link-dark {
    color:black;
}

.PageToggle .btn {
    font-family: 'Helvetica Neue', 'Roboto', sans-serif;
    font-weight:bold;
    text-decoration: none;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
    z-index: 999;
}

.PageToggle .btn:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.00);
    color: white;
}